import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CalendlyButtonLink from "@components/book-video-call/";

import  "./assets/floating-btn.scss";

import ArrowIcon from '@icons/arrow-teal.inline.svg';

const BtnLabel = (props) => (
    <>
        {props.label} <ArrowIcon className="icon-arrow-right animate-icon-arrow-right" />
    </>
)

const useScrollHandler = () => {
    const [scrollPosition, setSrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (scrollPosition)
}

const FloatingBtn = (props) => {
    const scrollPos = useScrollHandler();

    const data = useStaticQuery(graphql`
    query floatBook {
        strapiTeams(strapiId: {eq: "5f53c0eea195cb2114cbc4d1"}) {
            CalendlyLink
            Image {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    }
    `)
    return (
        <div className={`floating-book-btn${scrollPos > 600 ? ' float-btn-show' : ' float-btn-remove'}`}>
            <Img fixed={data.strapiTeams.Image.childImageSharp.fixed} className="floating-book-btn-image" />
            <CalendlyButtonLink
                label={<BtnLabel label={props.label} />}
                url={data.strapiTeams.CalendlyLink}
                btnClass={props.btnClass}
            />
        </div>
    );
}

// Specifies the default values for props:
FloatingBtn.defaultProps = {
    label: 'Book a Video Call',
    url: '',
    btnClass: 'btn btn-secondary link-arrow arrow-animation'
}

export default FloatingBtn;