import React from "react";
import Helmet from 'react-helmet';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeader from "@components/modules/content-header/";
import OurClients from "@components/client-logo/";
import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";
import FloatingBtn from "@components/floating-book-btn/";


const OurClientsPage = (props) => {
    const data = useStaticQuery(graphql`
        {
            allStrapiClients(filter: {}, sort: {fields: Title, order: ASC}) {
                edges {
                    node {
                        clientVideo {
                            id
                            showVideo
                            videoChannel
                            videoId
                            videoTitle
                            posterImage {
                                childImageSharp {
                                    fluid(maxWidth: 250) {
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
            },
            file(relativePath: { eq: "Candidate-Refer-A-Candidate-upload-CV.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return(
        <Layout>
                <SEO
                    title="Our Clients"
                    description="High-profile property industry names recommend recruitment advisors Crux Careers. Meet the top brands using Crux’ estate agency & PropTech recruitment services."
                />
                <Helmet 
                    bodyAttributes={{
                        class: 'white-bg'
                    }}
                />
                <Header />
                <Container>
                    <Row>
                        <Col xs={12} xl={8}>
                            <ContentHeader
                                Title="Here is a list of our valued clients and a selection of those that we are currently engaging with regarding potential placements."
                                pageName_1="About"
                                pageLink_1="/about"
                                lastClass_1=""
                                pageName_2="Our Clients"
                                pageLink_2="#"
                                lastClass_2="last"
                            />
                        </Col>
                        <Col xs={12}>
                            <OurClients
                                ourClients={data.allStrapiClients.edges}
                                videoLabel="Watch Review"
                            />
                        </Col>
                    </Row>
                </Container>
                
                
                <ImageTextModule
                    imgUrl={data.file.childImageSharp.fluid}
                    moduleData={
                        [
                            {
                                id: 1,
                                videoUrl: '',
                                Title: '',
                                Text: '',
                                Buttons: [
                                    {
                                        id: '123213',
                                        Label: 'Upload Your CV',
                                        externalLink: null,
                                        page: null,
                                        targetLink: '/candidates/upload-your-cv'
                                    }
                                ]
                            }
                        ]
                    }
                />
                <PopularSearch {...props}
                    sectionClass=" section-grey-bg"
                />
                <Footer />
                <FloatingBtn />
        </Layout>
    )
}

export default OurClientsPage
