import React, { useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import ModalVideo from 'react-modal-video';
import VideoIcon from '@icons/play-clients.inline.svg';
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "./assets/modal.scss";

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}

const VideoModalLinkImg = (props) => {
    const [isOpen, setOpen] = useState(false);
    
    return(
        <>
            <div href="#" role="button" tabIndex="0" onClick={()=> setOpen(true)} onKeyDown={()=> setOpen(true)} className="video">
                {!props.hideCircleIcon && <VideoIcon /> }{props.videoLabel}                    
            </div>

            {isOpen &&
                <ModalWindow>
                    <ModalVideo channel={props.videoChannel} isOpen={isOpen} videoId={props.videoId} onClose={() => setOpen(false)} />
                </ModalWindow>
            }
        </>
    )
}

VideoModalLinkImg.defaultProps = {
    hideCircleIcon: false
}

export default VideoModalLinkImg